<template>
  <Popup
    size="lg"
    modal-id="dashboard-notes"
    :on-close="onModalClose"
    :scrollable="true"
    :toggle-status="showPopup"
  >
    <template v-slot:header>
      {{ labels.notes }}
    </template>

    <template v-slot:body>
      <AuthorPost
        v-for="(item, index) in notes"
        :key="index"
        :data="item"
        @updatedPost="takeUpdateNote"
        @removeNote="deleteNote"
        @editPostStatus="updateNoteChangeStatus"
      />
      <div v-if="firstDataLoadPassed && !editingNote" class="text-editor">
        <TextEditorCard
          :update-key="editorUpdateKey"
          @changed="takeANote"
          :value="data.text"
        />
        <Button
          class="publish-button"
          :label="labels.send"
          variant="btn-purple"
          :styles="{ padding: '8px 15px', fontWeight: 700 }"
          :action="() => addNote()"
        />
      </div>
    </template>

    <template v-slot:footer>
      <Button
        variant="btn-outline-purple"
        :label="labels.close"
        :action="onModalClose"
      />
    </template>
  </Popup>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import Popup from "@/components/Popups/Popup";
import AuthorPost from "@/components/Author/AuthorPost";
import TextEditorCard from "@/components/Input/TextEditorCard";
import Button from "@/components/Buttons/Button";

export default {
  name: "Notes",
  components: {
    Popup,
    AuthorPost,
    TextEditorCard,
    Button,
  },
  props: {
    routeBack: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      notes: [],
      showPopup: true,
      data: {
        id: this.id,
        text: "",
      },
      firstDataLoadPassed: false,
      editorUpdateKey: Date.now(),
      editingNote: false,
    };
  },
  computed: {
    ...mapState("message", {
      singleData: "singleData",
    }),
    ...mapGetters("helper", ["labels"]),
    ...mapState("dashboard", {
      messages: "messages",
    }),
    ...mapState("user", ["username", "userFullName", "userid"]),
    id() {
      return this.$route.params.id;
    },
    isInEditor() {
      if (this.$route.name === "message-settings-notes") return true;

      return false;
    },
  },
  methods: {
    ...mapActions("message", {
      getSingle: "getSingle",
      updateMessage: "update",
      addNoteAction: "addNote",
      deleteNoteAction: "deleteNote",
      updateNoteAction: "updateNote",
    }),
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapMutations("message", { updateSingleMessage: "updateSingleMessage" }),
    onModalClose() {
      if (this.routeBack && Object.keys(this.routeBack).length) {
        this.$router.push(this.routeBack);
      } else {
        this.$router.back();
      }
      let element = document.querySelector(".modal-backdrop");
      if (element) {
        element.remove();
        document.querySelector("body").classList.remove("modal-open");
      }
    },
    addNote() {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();

      let content = this.data.text;

      this.addNoteAction({ id: this.id, content: content })
        .then((res) => {
          if (res.success) {
            this.notes.push({
              post: content,
              _id: res.data.notes[res.data.notes.length - 1]._id,
              user: this.userid,
              created: new Date().toISOString(),
              date: new Date().toISOString().slice(0, 10) || "",
              time: `${hours}:${minutes}`,
              name: this.noteAuthorName({
                _id: this.userid,
                username: this.username,
                name: this.userFullName,
              }),
            });

            this.data.text = "";
            this.editorUpdateKey = Date.now();

            this.addNotification({
              variant: "success",
              msg: this.labels.added_note,
              dismissSecs: 5,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    takeUpdateNote(updatedNote) {
      if (!updatedNote.post?.length) return;

      this.updateNoteAction({
        id: this.$route.params.id,
        note_id: updatedNote._id,
        text: updatedNote.post,
      })
        .then((res) => {
          if (res.success) {
            this.notes[updatedNote.key].text = updatedNote.post;
            this.addNotification({
              variant: "success",
              msg: this.labels.updated_note,
              dismissSecs: 5,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    deleteNote(deletedNote) {
      this.deleteNoteAction({
        id: this.$route.params.id,
        note_id: deletedNote._id,
        text: deletedNote.post,
      })
        .then((res) => {
          if (res.success) {
            this.notes = this.notes.filter(
              (note) => note._id !== deletedNote._id
            );
            this.addNotification({
              variant: "success",
              msg: this.labels.deleted_note,
              dismissSecs: 5,
            });
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    generateNotes() {
      if (this.singleData.notes && Array.isArray(this.singleData.notes)) {
        this.notes = [];

        this.singleData.notes.forEach((note, index) => {
          this.notes.push({
            _id: note._id,
            image: note.user?.avatar || "no-image",
            name: this.noteAuthorName(note.user),
            date: note.created?.slice(0, 10) || "",
            time: note.created?.slice(11, 16) || "",
            post: note.text,
            user: note?.user?._id,
            key: index,
          });
        });
        if (!this.firstDataLoadPassed) this.firstDataLoadPassed = true;
      }
    },
    takeANote(data) {
      this.data.text = data.value;
    },
    shouldRequestMessageData() {
      if (this.isInEditor) {
        if (Object.keys(this.singleData).length) return false;

        return true;
      }

      if (!this.isInEditor) {
        if (Array.isArray(this.messages)) {
          const indexOfMessage = this.messages.findIndex(
            (x) => x._id == this.id
          );

          if (indexOfMessage > -1) return false;
        }

        return true;
      }
    },
    requestMessage() {
      this.getSingle({ id: this.id })
        .then((res) => {
          if (res.success) {
            this.generateNotes();
          } else if (res.error) {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        })
        .catch((err) => console.log(err));
    },
    noteAuthorName(user) {
      if (user?.name?.first && user?.name?.middle && user?.name?.last)
        return `${user?.name.first} ${user?.name.middle} ${user?.name.last}`;
      if (user?.name?.first && user?.name?.last)
        return `${user?.name.first} ${user?.name.last}`;

      if (user?.username) return user?.username;

      return this.labels.unknown;
    },
    updateNoteChangeStatus(status) {
      this.editingNote = status;
    },
  },
  mounted() {
    const makeRequest = this.shouldRequestMessageData();

    if (!this.isInEditor && !makeRequest) {
      // Popup opened not from editor, request not needed which means that messaged can be taken from dashboard state
      const indexOfMessage = this.messages.findIndex((x) => x._id == this.id);

      this.updateSingleMessage(this.messages[indexOfMessage]);

      this.generateNotes();
    } else if (this.isInEditor && !makeRequest) {
      // Popup opened from editor and request not needed which means that message data already in state
      this.generateNotes();
    }
    // Despite of is opened from editor or no - message request required (which means that note is not in dashboard store under messages key and not in singleData of message store neither)
    if (makeRequest) this.requestMessage();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.text-editor {
  position: relative;
  .publish-button {
    right: 0;
    bottom: 0;
    margin-right: 1rem;
    margin-bottom: 1rem;
    position: absolute;
    cursor: pointer;
  }
}
</style>
